import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4be3d819"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "install"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.loader || !_ctx.session || !_ctx.code || !_ctx.shop)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Logo),
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Checkout our app on the app store!')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          fill: "clear",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToShopifyAppStore()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('View app')) + " ", 1),
                            _createVNode(_component_ion_icon, { icon: _ctx.openOutline }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}